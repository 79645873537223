import { React, SEO, styled } from '../shipityo'

import Layout from "../components/layout"
import PageHeading from "../components/pageheading"
import ProjectGallery from "../components/project-gallery"

const PeopleBox = styled.div`
  margin-bottom: 50px;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  @media only screen
    and (min-width: 500px)
    and (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
  }
  @media only screen
    and (min-width: 1px)
    and (max-width: 499px) {
      grid-template-columns: 1fr;
  }

`;

let ProjectMap = [
	{img: ["Biryani.jpg",]},
	{img: ["Bunny.jpg",]},
	{img: ["Chickentikkasalad.jpg",]},
	{img: ["Chillibites.jpg",]},
	{img: ["CurryChicken.jpg",]},
	{img: ["CurryLamb.jpg",]},
	{img: ["CurrySeafood.jpg",]},
	{img: ["CurryVegetrable.jpg",]},
	{img: ["Dessert1.jpg",]},
	{img: ["Dessert2.jpg",]},
	{img: ["Drumsofheaven.jpg",]},
	{img: ["Greeksalad.jpg",]},
	{img: ["IndoChinese.jpg",]},
	{img: ["Masalachips.jpg",]},
	{img: ["Papdams.jpg",]},
	{img: ["Rice.jpg",]},
	{img: ["Roti.jpg",]},
	{img: ["Samoosas.jpg",]},
	{img: ["Soup.jpg",]},
	{img: ["Tandoori.jpg",]},
	{img: ["Tea.jpg",]},
]

const Page= () => (
	<Layout>
		<SEO title="Gallery | Mount Everest Indian Restuarant" keywords={[
				"Mount Everest Indian Restuarant",
				"Indian Restuarant Hermanus", 
				"Indian Restuarant",
				"Restuarant Hermanus"
				]} 
		/>
		<PageHeading PageHeading="Gallery"></PageHeading>
		<PeopleBox>
		{ProjectMap.map(project => {
			return <ProjectGallery projectname={project.name} projectimg={project.img}></ProjectGallery>
		})}
		</PeopleBox>
  	</Layout>
)

export default Page
