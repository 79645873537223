import { React, styled, ImageYo } from '../shipityo'

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';


const PhotoGroup = styled.div`
  display: block;
  margin: auto;
  width: 80%;
  height: 80%;
  div{
    margin-bottom: 20px;

  }
`;

const ProjectCard = styled.div`
  display: block;
  padding-left: 9px;
  padding-right: 9px;
  div {
    margin-top: 7px;
    margin-bottom: 7px;
    padding-top: 7px;
    padding-bottom: 7px;
    text-align: center;
    background: white;
  }
`;


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: "#1C284B",
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    width: "100%",
    height: "200px",
    padding: "10px",
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let projectNameClosed = function (projectname, classes){
  return (
    <div>
      <span>{projectname}</span>
    </div>
  )
}

let projectNameOpen = function (projectname, classes){
  return (
    <Typography variant="h6" className={classes.title}>
      {projectname}
    </Typography>
  )
}







export default function FullScreenDialog( {projectname, projectimg} ) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  console.log("projectimg")
  console.log(projectimg)



  return (
    <div>
      <ProjectCard onClick={handleClickOpen}> 
        <ImageYo src={projectimg[0]}/>
        {projectname && projectNameClosed(projectname, classes)}
      </ProjectCard> 
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            {projectname && projectNameOpen(projectname, classes)}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <PhotoGroup>
          {projectimg.map(img => {
            return <ImageYo src={img}/>
          })}
        </PhotoGroup>
      </Dialog>
    </div>
  );
}
